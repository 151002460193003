<template>
  <div class="payment">
    <div class="d-flex justify-space-between px-4 pb-4">
      <div><label>Card Number</label></div>
      <div class="d-flex">
        <div class="mx-2">
          <img src="/img/cards/visa.svg" />
        </div>
        <div class="mx-2">
          <img src="/img/cards/master.svg" />
        </div>
        <div class="mx-2">
          <img src="/img/cards/american_express.svg" />
        </div>
      </div>
    </div>
    <!-- <v-radio-group row v-model="paymentType" class="payment-type">
      <v-radio name="payment-type" value="card">
        <template v-slot:label>
          <div class="d-flex flex-column justify-center">
            <div class="mx-auto">
              <i class="icon far fa-credit-card"></i>
            </div>
            <div class="text-center">
              <div>Credit Card</div>
            </div>
          </div>
        </template>
      </v-radio>
      <v-radio class="ml-5" name="payment-type" value="ach">
        <template v-slot:label>
          <div class="d-flex flex-column justify-center">
            <div class="mx-auto">
              <i class="icon fas fa-university"></i>
            </div>
            <div class="text-center">
              <div>ACH</div>
            </div>
          </div>
        </template>
      </v-radio>
    </v-radio-group>-->
    <div id="card" v-if="paymentType == 'card'" class="credit-card-inputs" :class="{ complete }">
      <card-number
        class="form-control stripe-element card-number"
        ref="cardNumber"
        :stripe="stripeKey"
        :options="stripeOptions"
        @change="number = $event.complete"
      />
      <card-expiry
        class="form-control stripe-element card-expiry"
        ref="cardExpiry"
        :stripe="stripeKey"
        :options="stripeOptions"
        @change="expiry = $event.complete"
      />
      <card-cvc
        class="form-control stripe-element card-cvc"
        ref="cardCvc"
        :stripe="stripeKey"
        :options="stripeOptions"
        @change="cvc = $event.complete"
      />
      <div id="errorMessage" class="alert" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
    <!-- <div id="ach" v-if="paymentType == 'ach'">
      <v-btn id="linkButton" @click="plaidLinkHandler.open()" color="primary">Connect To Your Bank</v-btn>
      <div v-if="accounts">
        <div class="mt-5">
          <strong>Please select the account you want to use.</strong>
        </div>
        <v-radio-group>
          <v-radio
            v-for="account in accounts"
            :key="account.id"
            :value="account.id"
            @change="setAccount(publicToken, account)"
            :label="account.name"
          ></v-radio>
        </v-radio-group>
      </div>
    </div>-->
  </div>
</template>
<style scoped lang="scss">
#card {
  background-color: #f2f2f2;
}
.stripe-element {
  margin: 12px 0;
  padding: 3px;
  background-color: white;
}
.alert {
  color: red;
}
.icon {
  font-size: 40px;
  margin-top: -25px;
}
.payment-type {
  margin-top: 35px;
}
// .credit-card-inputs.complete {
//   border: 2px solid green;
// }

.stripe-element {
  margin: 5px;
}

.payment-icon {
  border-radius: 0.2142857143em;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 2.7142857143em;
  height: 1.7142857143em;
}
.payment-icon--visa {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/visa-319d545….svg), none;
}
.payment-icon--master {
  background-image: url(//cdn.shopify.com/s/assets/payment_icons/master-173035b….svg), none;
}
.payment-icon {
  display: inline-block;
  width: 38px;
  height: 24px;
  -webkit-transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  transition: opacity 0.5s cubic-bezier(0.3, 0, 0, 1);
  -webkit-backface-visibility: hidden;
}
</style>
<script>
import Vue from "vue";
import { CardNumber, CardExpiry, CardCvc, createToken } from "vue-stripe-elements-plus";

export default Vue.extend({
  name: "Payment",
  components: { CardNumber, CardExpiry, CardCvc },
  data() {
    return {
      accounts: null,
      publicToken: null,
      errorMessage: null,
      paymentType: "card",
      complete: false,
      number: false,
      expiry: false,
      cvc: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      // stripeKey: "pk_test_fB4eNBnC3LJHZS7268TzPAbd00BAIgZfbs",
      stripeKey:
        window.location.host.indexOf("app.gapsellingonlinetraining.com") > -1
          ? "pk_live_CHTGjmD0toOMq4P4T8i9RcyC00ocGxtFQZ"
          : "pk_test_fB4eNBnC3LJHZS7268TzPAbd00BAIgZfbs",
      // plaidLinkHandler: null,
      // plaidPayment: null
    };
  },
  //
  mounted() {
    const self = this;
    // this.plaidLinkHandler = Plaid.create({
    //   clientName: "Client Name",
    //   // Change sandbox to development to test with live users;
    //   // Change to production when you're ready to go live!
    //   env: "sandbox",
    //   // Replace with your public_key from the Dashboard
    //   key: "db1cc5dc17761baa62463d684c7e8b",
    //   product: ["auth"],
    //   onLoad() {
    //     // The Link module finished loading.
    //   },
    //   onSuccess(publicToken, metadata) {
    //     // The onSuccess function is called when the user has
    //     // successfully authenticated and selected an account to use.
    //     //
    //     // When called, you will send the public_token and the selected
    //     // account ID, metadata.account_id, to your backend app server.
    //     //
    //     // sendDataToBackendServer({
    //     //   public_token: public_token,
    //     //   account_id: metadata.account_id
    //     // });
    //     console.log(`Public Token: ${publicToken}`);
    //     console.log(`Selected account ID: ${metadata.account_id}`);
    //     self.publicToken = publicToken;
    //     self.accounts = metadata.accounts;
    //     // const data = {
    //     //   plaidPayment: {
    //     //     token: public_token,
    //     //     accountId: metadata.accounts[0].id
    //     //   }
    //     // };
    //     // self.$emit("input", data);
    //   },
    //   onExit(err) {
    //     // The user exited the Link flow.
    //     if (err != null) {
    //       // The user encountered a Plaid API error prior to exiting.
    //     }
    //     // metadata contains information about the institution
    //     // that the user selected and the most recent API request IDs.
    //     // Storing this information can be helpful for support.
    //   }
    // });
  },
  //
  watch: {
    number() {
      this.update();
    },
    expiry() {
      this.update();
    },
    cvc() {
      this.update();
    },
  },
  methods: {
    // setAccount(token, account) {
    //   console.log("account", account);
    //   const data = {
    //     plaidPayment: {
    //       token,
    //       accountId: account.id
    //     }
    //   };
    //   this.$emit("input", data);
    // },
    // onACHSuccess(token) {
    //   console.log(token);
    // },
    update() {
      this.errorMessage = null;
      this.complete = this.number && this.expiry && this.cvc;
      if (this.complete) {
        this.$emit("complete");
      }
      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus();
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus();
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus();
        } else if (!this.number) {
          this.$refs.cardNumber.focus();
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    pay() {
      return new Promise((resolve, reject) => {
        // createToken returns a Promise which resolves in a result object with
        // either a token or an error key.
        // See https://stripe.com/docs/api#tokens for the token object.
        // See https://stripe.com/docs/api#errors for the error object.
        // More general https://stripe.com/docs/stripe.js#stripe-create-token.
        createToken().then((data) => {
          if (data && data.error) {
            this.errorMessage = data.error.message;
            this.$emit("updateTokenError");
            reject();
          } else {
            this.$emit("input", data);
            console.log(data.token);
            resolve(data);
          }
        });
      });
    },
  },
});
</script>
